<template>
  <div class="chapterForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="年级分类"
        :rules="[
          { required: true, message: '请选择年级分类', trigger: 'blur' },
        ]"
        prop="gradeType"
      >
        <v-select :options="gradeList" v-model="form.gradeType" />
      </el-form-item>
      <el-form-item
        label="科目分类"
        :rules="[
          { required: true, message: '请选择科目分类', trigger: 'blur' },
        ]"
        prop="courseType"
      >
        <v-select :options="classes" v-model="form.courseType" />
      </el-form-item>
      <el-form-item
        label="章节名称"
        :rules="[
          { required: true, message: '请输入章节名称', trigger: 'blur' },
        ]"
        prop="chapterTitle"
      >
        <v-input
          placeholder="请输入章节名称"
          v-model="form.chapterTitle"
          :width="250"
          :maxlength="50"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="排序"
        :rules="[{ required: true, message: '请输入排序', trigger: 'blur' }]"
        prop="sortIndex"
      >
        <v-input
          placeholder="请输入排序"
          v-model="form.sortIndex"
          :width="250"
          type="number"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { getChapterDetailUrl, addChapterUrl } from "./api";
import {
  classesMap,
  classesOps,
  gradeListMap,
  classes,
  gradeList,
} from "./map";
export default {
  name: "goodsEdit",
  data() {
    return {
      gradeList,
      classes,
      submitConfig: {
        queryUrl: getChapterDetailUrl,
        submitUrl: addChapterUrl,
      },
      teacherSignList: [],
      form: {
        id: "",
        gradeType: "",
        chapterTitle: "",
        courseType: "",
        sortIndex: 0,
      },
    };
  },
  mounted() {
    const { id, communityId } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ chapterId: id });
    } else {
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      return true;
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      this.form.id = data.id;
    },
  },
};
</script>
<style lang="less" scoped>
.chapterForm {
  box-sizing: border-box;
  height: 100%;
}
</style>
<style lang="less">
.chapterForm {
}
</style>
